html {
  height: 100%;
  overflow: visible;
}

body {
  /* min-height: 100vh; */
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: visible;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100%;
  overflow: visible;
}

@font-face {
  font-family: "Formula1";
  src: local("Formula1Bold"),
   url("./fonts/Formula1-Bold.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Formula1";
  src: local("Formula1"),
   url("./fonts/Formula1-Regular.otf") format("truetype");
  font-weight: normal;
}

